import React, { useState } from 'react';
import logo from "../asset/logo.png"
import { RiContactsLine } from "react-icons/ri";
import { MdOutlineContactEmergency } from "react-icons/md";
import { IoClose } from "react-icons/io5"
import axios from 'axios'
import { apiBaseUrl } from '../utils';
import { isValidEmail } from "../utils/csvreader";

const Header = ({ handleSubscribe }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!isValidEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        axios.post(`${apiBaseUrl}/subscribe`, { email: email })
            .then(response => {
                if (response.status === 200) {
                    handleSubscribe();
                    // clear email
                    setEmail('')
                }
            })
            .catch(error => console.error(error));
    };

    return (
        <header className="bg-black  shadow text-white text-lg  w-full pb-0">

            <div className="max-w-[1340px]  mx-auto w-full relative grid grid-cols-3 gap-4 items-center justify-center">
                <form onSubmit={handleSubmit} className='py-2 flex flex-col items-center gap-4 h-full  justify-center'>
                    {error && <span className='text-red-500'>{error}</span>}
                    <div className='flex gap-4'>
                        <label className='flex flex-col gap-4'>
                            <input className="bg-black text-white border-gray-300 border px-4 py-2 rounded-md" type="text" value={email} onChange={(e) => {
                                setError('')
                                setEmail(e.target.value)
                            }} placeholder="Your Email" required />
                        </label>
                        <button
                            type='submit'
                            disabled={error}
                            className={`text-white cursor-pointer h-11 w-max px-4 rounded-full hide-show-button hover:opacity-75 flex items-center gap-2 text-lg
                    ${error ? 'cursor-not-allowed' : ''} `}>
                            <MdOutlineContactEmergency style={{ fontSize: "20px" }} />
                             Subscribe Mailinglist</button>
                    </div>
                </form>
                <div className=' px-10 rounded-full flex justify-center items-center py-3'>
                    <a href="/">
                        {/* <img src={logo} alt="logo" className='max-h-32 max-w-32' /> */}
                        <img className='max-h-32 max-w-32' src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo" />
                    </a>
                    <a className='flex  gap-3  items-center absolute right-28 hover:text-white text-green-500 text-lg hover:bg-[#1A1B20] hover:shadow-lg rounded-md px-3 py-2' href="mailto:info@aucdom.com" target="_blank" rel="noopener noreferrer">
                        <RiContactsLine />
                        Contact</a>
                </div>

            </div>

            <div className="relative overflow-hidden w-full h-1">
                <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-green-500 to-green-500"></div>
            </div>
        </header>
    );
};

export default Header;
